import { useStaticQuery, graphql } from "gatsby"
export const InstagramData = lang => {
  const data = useStaticQuery(
    graphql`
      query Instagram {
        wpgraphql {
          themesOptionsPage {
            template {
              instagram {
                description
                fieldGroupName
                heading {
                  target
                  title
                  url
                }
                images {
                  link
                  image {
                    sourceUrl
                    databaseId
                    modified
                    imageFile {
                      childImageSharp {
                        fluid(maxWidth: 318) {
                          base64
                          aspectRatio
                          src
                          srcSet
                          sizes
                          srcWebp
                          srcSetWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          themesOptionsPageEn {
            template {
              instagram {
                description
                fieldGroupName
                heading {
                  target
                  title
                  url
                }
                images {
                  link
                  image {
                    sourceUrl
                    databaseId
                    modified
                    imageFile {
                      childImageSharp {
                        fluid(maxWidth: 318) {
                          base64
                          aspectRatio
                          src
                          srcSet
                          sizes
                          srcWebp
                          srcSetWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          themesOptionsPageDe {
            template {
              instagram {
                description
                fieldGroupName
                heading {
                  target
                  title
                  url
                }
                images {
                  link
                  image {
                    sourceUrl
                    databaseId
                    modified
                    imageFile {
                      childImageSharp {
                        fluid(maxWidth: 318) {
                          base64
                          aspectRatio
                          src
                          srcSet
                          sizes
                          srcWebp
                          srcSetWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          themesOptionsPageFr {
            template {
              instagram {
                description
                fieldGroupName
                heading {
                  target
                  title
                  url
                }
                images {
                  link
                  image {
                    sourceUrl
                    databaseId
                    modified
                    imageFile {
                      childImageSharp {
                        fluid(maxWidth: 318) {
                          base64
                          aspectRatio
                          src
                          srcSet
                          sizes
                          srcWebp
                          srcSetWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  switch (lang) {
    case "pl":
      return data.wpgraphql.themesOptionsPage.template.instagram
    case "en":
      return data.wpgraphql.themesOptionsPageEn.template.instagram
    case "de":
      return data.wpgraphql.themesOptionsPageDe.template.instagram
    case "fr":
      return data.wpgraphql.themesOptionsPageFr.template.instagram
    default:
      return data.wpgraphql.themesOptionsPage.template.instagram
  }
}
